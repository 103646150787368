import axios from 'axios';
import { createSignature } from 'crypto-pro';
import { saveAs } from 'file-saver';
import _ from 'lodash';
import { createAction } from 'redux-actions';

import * as api from '../api';
import { base64toBlob } from '../lib/ccpa';
import * as fetch from '../lib/fetch';
import socket from '../lib/mqtt';
import * as topics from '../topics';

export const addExams = createAction('EXAMS_ADD');
export const addExam = createAction('EXAM_ADD');
export const updateExam = createAction('EXAM_UPDATE');

export const cleanUp = createAction('CLEAN_UP');

export const fetchExamsRequest = createAction('REQUEST_FETCH_EXAMS');
export const fetchExamsSuccess = createAction('SUCCESS_FETCH_EXAMS');
export const fetchExamsFailure = createAction('FAILURE_FETCH_EXAMS');

export const fetchJournalRequest = createAction('REQUEST_FETCH_JOURNAL');
export const fetchJournalSuccess = createAction('SUCCESS_FETCH_JOURNAL');
export const fetchJournalFailure = createAction('FAILURE_FETCH_JOURNAL');

export const fetchExams = (
  {
    contracts,
    startOfDay,
    endOfDay,
  },
): any => async (dispatch: any): Promise<any> => {
  dispatch(fetchExamsRequest({
    contracts,
    startOfDay,
    endOfDay,
  }));
  try {
    const exams = await fetch.fetchExams({ contracts, startOfDay, endOfDay });
    dispatch(fetchExamsSuccess({ exams }));
    dispatch(cleanUp());
    // setInterval(() => dispatch(cleanUp()), 150000);
  } catch (error) {
    dispatch(fetchExamsFailure(error?.toString()));
  }
};

export const fetchJournal = (
  {
    contracts,
    examTypeId,
    startOfDay,
    endOfDay,
    type,
    Worker,
  },
): any => async (dispatch: any): Promise<any> => {
  dispatch(fetchJournalRequest({ type }));
  try {
    const exams = await fetch.fetchExams({ contracts, startOfDay, endOfDay, status: 'signed', Worker, examTypeId });

    const templateName = ['/Journal/journal-', type].join('');

    const request =
    {
      "template": { "name": templateName },
      "data": { data: exams, platform_path: process.env.REACT_APP_PLATFORM_HOST },
      "options": { "reports": { "save": true } }
    };

    const resp = await axios.post(
      "https://report.stage.medregister.online/api/report",
      JSON.stringify(request),
      {
        headers: {
          'Content-Type': 'application/json',
          'Content-Disposition': 'attachment\;'
        },
        responseType: "blob",
      },
    );

    const { data: blob } = resp;
    saveAs(blob, ['journal', type].join('.'));

    dispatch(fetchJournalSuccess({ type }));
  } catch (error) {
    dispatch(fetchJournalFailure({ message: error?.toString(), type }));
  }
};

export const changeAllowanceRequest = createAction('REQUEST_CHANGE_ALLOWANCE');
export const changeAllowanceSuccess = createAction('SUCCESS_CHANGE_ALLOWANCE');
export const changeAllowanceFailure = createAction('FAILURE_CHANGE_ALLOWANCE');

export const changeExamAllowance = ({ id, allowance, nurse_mode }) => async (dispatch, getState) => {
  const { user, certs } = getState();
  const { Roles } = user;
  const isNurse = _.includes(Roles, 2);
  const { thumbprint } = certs.currentCertificate;
  if (!isNurse || !thumbprint) return;

  dispatch(changeAllowanceRequest());
  try {
    const formData = new FormData();
    formData.append('id', id);
    formData.append('allowance', allowance);
    formData.append('nurse_mode', nurse_mode ? nurse_mode : 'manual');
    const currentTime = new Date();
    const nurse_local_time_offset = -currentTime.getTimezoneOffset() * 60;
    formData.append('nurse_local_time_offset', String(nurse_local_time_offset));
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };

    const resp = await axios.put(api.exams(), formData, config);
    if (resp.status === 200 && resp.data.success) {
      const { data: exam } = resp.data;
      const { Nurse } = exam;
      const topic = topics.exam(Nurse.id, id);
      await socket.publish(topic, JSON.stringify({ exam }));
      dispatch(changeAllowanceSuccess());
    }
  } catch (error) {
    dispatch(changeAllowanceFailure('Couldn’t change allowances. Try again'));
  }
};

export const pendingAllowance = ({ exam }): any => (dispatch, getState) => {
  const {
    id,
    isSysNormal,
    isDiaNormal,
    isPulseNormal,
    isTemperatureNormal,
    isAlcoholNormal,
    is_control_alcohol_photo_similar,
    is_control_pressure_photo_similar,
    is_control_temperature_photo_similar,
    complaints,
  } = exam;

  const { user, certs } = getState();
  const { Roles, approvalMode } = user;
  const isNurse = _.includes(Roles, 2);
  const { thumbprint } = certs.currentCertificate;

  if (!isNurse || !thumbprint || exam.Nurse.id !== user.id) {
    dispatch(updateExam({ exam }));
    return;
  }

  const allowance = isSysNormal
    && isDiaNormal
    && isPulseNormal
    && isTemperatureNormal
    && isAlcoholNormal
    && !complaints;

  const nextAction = {
    auto: () => changeExamAllowance({ id, allowance, nurse_mode: 'auto' }),
    'semi-auto': () => (
      allowance
        && is_control_alcohol_photo_similar
        && is_control_pressure_photo_similar
        && is_control_temperature_photo_similar
        ? changeExamAllowance({ id, allowance, nurse_mode: 'semi-auto' })
        : updateExam({ exam })),
    manual: () => {
      let audio = new Audio('/notification.mp3');
      audio.play();

      return updateExam({ exam })
    },
  };

  const getNextAction = (mode: string) => nextAction[mode];

  const next = getNextAction(approvalMode);
  dispatch(next());
};

export const signExamRequest = createAction('REQUEST_EXAM_SIGN');
export const signExamSuccess = createAction('SUCCESS_EXAM_SIGN');
export const signExamFailure = createAction('FAILURE_EXAM_SIGN');

export const signExam = ({ exam }): any => async (dispatch, getState) => {
  const { user, certs } = getState();
  const { Roles } = user;
  const isNurse = _.includes(Roles, 2);
  const { thumbprint } = certs.currentCertificate;



  if (!isNurse || !thumbprint) return;

  const { id: examId, Nurse, reportId } = exam;

  console.log('signExam; exam.id ', exam.id, 'Nurse.id', Nurse.id, 'user.id', user.id);

  if (Nurse.id !== user.id) {
    return;
  }

  dispatch(signExamRequest());
  try {
    const formData = new FormData();
    formData.append('id', examId);
    if (thumbprint && reportId) {
      const respReport = await axios.get(api.examReport(examId), { responseType: 'arraybuffer' });
      const base64report = Buffer.from(respReport.data, 'binary').toString('base64');

      const base64Signature = await createSignature(thumbprint, base64report);
      const blobSignature = base64toBlob(base64Signature);
      formData.append('NURSE_SIGN', blobSignature);
    }

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };

    const resp = await axios.put(api.exams(), formData, config);
    const { data: patchedData, success } = resp.data;
    if (resp.status === 200 && success) {
      const topic = topics.exam(Nurse.id, examId);
      await socket.publish(topic, JSON.stringify({ exam: patchedData }), { qos: 2 });
    }
  } catch (error) {
    dispatch(signExamFailure('Could’t sign exam. Try again'));
  }
};
